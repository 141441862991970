<template>
  <div class="head-container">
    <!-- 搜索 -->
    <el-cascader
      size="mini"
      class="filter-item"
      v-model="query.type"
      style="width: 300px;"
      placeholder="支付渠道查询"
      :options="cascaderOption"
      :props="props"
      :collapse-tags="true"
      clearable
      @change="toQuery"
    ></el-cascader>
    <el-input
        v-model="query.login"
        clearable
        placeholder="MT4账号"
        type='number'
        style="width: 150px;"
        class="filter-item"
        @keyup.enter.native="toQuery"
    />
    <el-input
        v-model="query.belongId"
        clearable
        placeholder="代理号"
        type='number'
        style="width: 150px;"
        class="filter-item"
        @keyup.enter.native="toQuery"
    />
    <el-input
        v-model="query.managerInfo"
        clearable
        placeholder="客户经理"
        style="width: 150px;"
        class="filter-item"
        @keyup.enter.native="toQuery"
    />
     <div class="filter-item">
      完成时间
      <el-date-picker
        v-model="query.startTime"
        type="datetime"
        placeholder="开始时间">
      </el-date-picker>
      至
      <el-date-picker
        v-model="query.endTime"
        type="datetime"
        placeholder="结束时间">
      </el-date-picker>
    </div>
    <el-button class="filter-item" size="mini" type="primary" icon="el-icon-search" @click="toQuery">搜索</el-button>
    <el-button class="filter-item" size="mini" type="primary" icon="el-icon-close" @click="clear">清空</el-button>
    <el-button
            v-if="checkPermission(['EXPORT_DEPOSIT_WITHDRAW_RECORD','ADMIN'])"
            :loading="loading"
            class="filter-item"
            size="mini"
            type="primary"
            icon="el-icon-search"
            @click="exportExecl"
    >导出</el-button>
  </div>
</template>

<script>
  import { getPayTypeSelectList } from '@/api/cus/tCustDepositWithdraw'
import checkPermission from '@/utils/permission' // 权限判断函数
export default {
  props: {
    query: {
      type: Object,
      required: true
    }
  },
  data(){
    return {
      loading:false,
      cascaderOption:[
        {
          value: 1,
          label: '入金',
          children: [
            {
              value: 1,
              label: '渠道',
              children: [],
            },
            {
              value: 36,
              label: '佣金转入',
            },
            {
              value: 4,
              label: '其他',
              children: [
                {
                  value: 35,
                  label: 'COMMI_BONUS'
                },
                {
                  value: 38,
                  label: '赠金'
                },
                {
                  value: 99,
                  label: '其他'
                }
              ]
            },
          ],
        },
        {
          value: 2,
          label: '出金',
          children: [
            {
              value: 1,
              label: '渠道',
              children: [],
            },
          ],
        },
      ],
      props: { multiple: true,expandTrigger: 'hover' },
    }
  },
  created() {
    this.getPayTypeList();
  },
  methods: {
    checkPermission,
    toQuery() {
      this.$parent.page = 0
      this.$parent.init()
    },
    clear() {
      this.$emit('clear');
    },
    exportExecl(){
      this.loading=true
      this.$emit('export-execl');
    },
    getPayTypeList(){
      getPayTypeSelectList({type: 1}).then((res) => {
        let tempArr = [];
        for(let key in res ){
          if(res.hasOwnProperty(key)){
            tempArr.push({
              value: key,
              label: res[key]
            })
          }
        }
        this.cascaderOption[0].children[0].children = tempArr;
      });
      getPayTypeSelectList({type: 2}).then((res) => {
        let tempArr = [];
        for(let key in res ){
          if(res.hasOwnProperty(key)){
            tempArr.push({
              value: key,
              label: res[key]
            })
          }
        }
        this.cascaderOption[1].children[0].children = tempArr;
      });
    }
  }
}
</script>
