<template>
  <div class="app-container summary-page">
    <eHeader
      ref='header'
             :query="query"
             @export-execl="exportExecl"
             @clear="clearHandle"
    />
    <!--表格渲染-->
    <blockquote class="my-blockquote statistics-box">
      <div class="statistics-item">
        总额:${{statistics.amountSum}}
        <el-divider direction="vertical" class="statistics-item-divider"></el-divider>
      </div>
      <div class="statistics-item">
        入金: ${{statistics.deposit}}
        <el-divider direction="vertical" class="statistics-item-divider"></el-divider>
      </div>
      <div class="statistics-item">
        出金: ${{statistics.withdraw}}
        <el-divider direction="vertical" class="statistics-item-divider"></el-divider>
      </div>
    </blockquote>
    <el-table
      v-loading="loading"
      :data="data"
      stripe
      size="small"
      style="width: 100%;"
    >
      <el-table-column prop="rank" label="序号">
          <template slot-scope="scope">
            <span>{{getOrder(scope.$index)}}</span>
          </template>
      </el-table-column>
      <el-table-column prop="transactionId" label="订单号"/>
      <el-table-column prop="login" label="MT4账户"/>
       <el-table-column prop="belongId" label="代理号"/>
      <el-table-column prop="manager" label="客户经理">
        <template slot-scope="scope">
            <span v-if="scope.row.managerName!=null">{{scope.row.managerName}}<br></span>
            <span v-if="scope.row.email!=null">{{scope.row.email}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="type" label="交易类型">
        <template slot-scope="scope">
          <div>{{scope.row.type}}</div>
          <div>{{scope.row.payType}}</div>
        </template>
      </el-table-column>
      <el-table-column prop="amount" label="金额（$）"/>
      <el-table-column prop="successTime" label="审核通过时间">
        <template slot-scope="scope">
          <span>{{ parseTime(scope.row.successTime) }}</span>
        </template>
      </el-table-column>
    </el-table>
    <!--分页组件-->
    <public-pagination
            :total-page="total"
            :current-page="page"
            @pageChange="pageChange"
            @sizeChange="sizeChange"
    ></public-pagination>
  </div>
</template>

<script>
import { gePayTypeCommon } from '@/utils/index'
import checkPermission from '@/utils/permission'
import initDataComm from '@/mixins/initData'
import { initData } from '@/api/data'
import { parseTime } from '@/utils/index'
import { export_json_to_excel,formatJson } from '@/vendor/Export2Excel'
import eHeader from '@/components/stat/depwithlist/header'
export default {
  name:'depwithlist',
  components: { eHeader },
  mixins: [initDataComm],
  data() {
    return {
      loading: false,
      sup_this: this,
      statistics:{
        amountSum: '',
        deposit: '',
        withdraw: '',
        commission: '',
        turn: '',
      }
    }
  },
  created() {
    this.$nextTick(() => {
      this.init()
    })
  },
  methods: {
    gePayTypeCommon,
    parseTime,
    checkPermission,
    export_json_to_excel,
    formatJson,
    initData,
    beforeInit() {
      this.url = '/crm/queryStatisticsDeposit';
      const sort = 'id,desc';
      this.params = { page: this.page, size: this.size,export:false};
      const query = this.query
      const type = query.type;
      const startTime = this.parseTime(query.startTime);
      const endTime = this.parseTime(query.endTime);
      if(this.query.login){
        this.params.login = this.query.login;
      }
      if(this.query.belongId){
        this.params.belongId = this.query.belongId;
      }
      if(this.query.managerInfo){
        this.params.managerInfo = this.query.managerInfo;
      }
      if (startTime!=null && startTime!='') { this.params['startTime'] = startTime }
      if (endTime!=null && endTime!='') { this.params['endTime'] = endTime }
      // types需要修改

      if (type!=null && type!='' &&(type && type.length)) {
        this.params['payTypes'] = this.gePayTypeCommon(type);
        this.params['types'] = this.disposeType(type);
      }
      return true;
    },
    clearHandle(){
      this.query.type='';
      this.query.startTime='';
      this.query.endTime='';
      this.query.login='';
      this.query.belongId='';
      this.query.managerInfo='';
      this.toQuery();
    },
    exportExecl(){
      const query = this.query;
      const type = query.type;
      const startTime = this.parseTime(query.startTime);
      const endTime = this.parseTime(query.endTime);
      if (type!=null && type!='' &&(type && type.length)) {
        this.params['payTypes'] = this.gePayTypeCommon(type);
        this.params['types'] = this.disposeType(type);
      }
      if (startTime!=null && startTime!='') { this.params['startTime'] = startTime };
      if (endTime!=null && endTime!='') { this.params['endTime'] = endTime };
      this.params.export = true;
      this.initData('/crm/queryStatisticsDeposit', this.params).then(res => {
        this.$refs['header'].loading =false;
        const tHeader =['订单号', 'MT4账户','上级代理','客户经理','客户经理邮箱','类型', '金额','完成时间'];
        const filterVal =['transactionId', 'login','belongId','managerName','email','type', 'amount','successTime'];
        const exportData = formatJson(filterVal,res.content);
        let fileName='出入金明细报表';
        const query = this.query;
        const startTime = this.parseTime(query.startTime);
        const endTime = this.parseTime(query.endTime);

        if (startTime!=null && startTime!='') { fileName = fileName+'-'+this.parseTime(query.startTime) }
        if (endTime!=null && endTime!='') { fileName = fileName+'-'+this.parseTime(query.endTime) }
        export_json_to_excel(tHeader,exportData,fileName);
      }).catch(err => {
         this.$refs['header'].loading =false;
      })
    },
    // 传递types, 出金传1,入金传2,出入金都有传1,2
    disposeType(array){
      let isHasOuter = array.some((ele) => {
        return ele[0] == 2;
      });
      let isHasEnter = array.some((ele) => {
        return ele[0] == 1;
      });
      let tempArr = [];
      if(isHasEnter){
        tempArr.push(1);
      }
      if(isHasOuter){
        tempArr.push(2);
      }
      return tempArr.join();
    },
    getOrder(index){
      return parseInt(this.page + "1") + index;
      /*if(index == 9){
        return (this.page + 1) + '' + 0;
      }else {
        return this.page + '' + (index + 1);
      }*/
    }
  }
}
</script>

